import React from 'react';
import ContentHead from './ContentHead';

export default function Education() {
  return (
    <div className="bg-light p-4">
      <ContentHead title="Work Experience" />
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <h4 className="font-size-lg d-none flex-column align-items-center text-primary mb-4 mt-3">
            <i className="fas fa-briefcase font-size-xl text-gray-600 mb-2" />
            <span className="">Experience</span>
          </h4>
          <div
            className="pl-4 py-2"
            style={{
              borderLeft: `2px dotted #dbe0ec`,
            }}
          >
            <ExperienceItem
              date="October 2020 - Present"
              school="Obytes"
              job="Frontend engineer"
              description={`
             <b> Project </b>: <a href="https://gakko.ac/">Saas</a> for preschools that manage students, guardians, teachers, classrooms, lessons .. .
             <br/> <b> Frontend technologies </b> : Reactjs, Tailwind, React Query, React Hooks, React Context API, RestFull API, Cypress.. .
             <br/> <b> Continuous Integration/Continuous Deployment  </b>: GithubActions, Netlify . 
             <br/> <b> Collaboration tools </b>: Github, Slack, Linear, Notion . 
             <br/> <b> Error Tracking and Session Replay </b> : LogRocket, Sentry .
             <br/> <b> Missions: </b> <ul>
             <li>Develop and maintain the front end of our web applications using React. </li>
             <li>Collaborate with back-end developers to integrate the front-end with the back-end application.</li>
             <li>Write efficient and maintainable code using best practices and design patterns.</li>
             <li>Develop and maintain unit tests for front-end code.</li>
             <li>Participate in code reviews and provide feedback to improve code quality.</li>
             <li>Work closely with the UX/UI team to ensure a seamless user experience.</li>
             <li>Participating in release and sprint meetings (Planning/Retro/Review/Standup). </li>
             <li>Close collaboration with experienced and dedicated peer engineers. </li>
             </ul>`}
            />
            <ExperienceItem
              date="Sept 2018 - May 2020"
              school="LaFactory - ScreenDy"
              job="Frontend engineer"
              description={`
              <b> Project </b>: ScreenDy is a drag and drop mobile editor, that lets users create their own mobile apps without coding.
              I mainly worked on the platform itself, adding functionalities and implementing new features.
              <br/> <b>  Frontend technologies </b> : Reactjs, Nextjs, Bootstarp, Redux, Stripe, Axios, RestFull API.. .
              <br/> <b> Continuous Integration/Continuous Deployment  </b>: GithubActions, Netlify . 
              <br/> <b> Missions: </b> <ul>
                <li>Created the new <a href="https://screendy.com" target="_blank">ScreenDy website</a>.</li>
                <li>Added a multiple classnames support for the ui <a  href="https://platform.screendy.com" target="_blank">Editor</a>.</li>
                <li>Stripe payment support.</li>
                <li>Created a showcase platform where the users submit their best apps.</li>
                <li>Created the Screendy Marketplace.</li>
                <li>Created and updated templates.</li>
                <li>New in-boarding for the users.</li>
                <li>Updated the ui and more functionalities to the previewer.</li>
                <li>Created CRM for the La factory.</li>
                <li>Created a CDN for the Lafactory medias.</li>
              </ul>
            `}
            />
            <ExperienceItem
              date="February - July 2018"
              school="Softcenter"
              job="Internship"
              description={`
            <b>Project</b>: Platform which showcase all screendy ressources (templates and project) and where users can see preview and buy their favourite ressources.
            <br/> <b> Frontend technologies </b> : React, Redux, Javascript(ECS6+), Typescript, RestFull API .. .
            <br/> <b> Missions: </b> <ul>
              <li>Market Research and Project studies.</li>
              <li>Detail the design analysis phases.</li>
              <li>Organize tasks and prioritize MVP features.</li>
              <liDevelop and maintain the front end of the platform.</li>
              <li>Close collaboration with experienced and dedicated peer engineers.</li>
            </ul>`}
            />
            <ExperienceItem
              date="August 2016"
              school="O'lfactive"
              job="Internship"
              description={`
            <b>Project</b>: Mobile application for vehicule materials using: Android (mobile version of the website wooto.fr)
            <br/> <b> Technologies </b> : Android.
            <br/> <b> Missions: </b> <ul>
              <li>Project studies.</li>
              <li>Develop and maintain the application.</li>
              <li>Connect the application to back-end services.</li>
              <li>Ensure the quality, and responsiveness of the application.</li>
            </ul>
            
            `}
            />
          </div>
        </div>
      </div>
      <ContentHead title="Education & Skills" />
      <div className="row">
        <div className="col-12 col-xl-6">
          <h4 className="font-size-lg d-inline-flex flex-column align-items-center text-primary mb-4 mt-3">
            <i className="fas fa-graduation-cap font-size-xl text-gray-600 mb-2" />
            <span className="">Education</span>
          </h4>
          <div
            className="pl-4 py-2"
            style={{
              borderLeft: `2px dotted #dbe0ec`,
            }}
          >
            <ExperienceItem
              date="2016 - 2018"
              school="ENSIAS - RABAT"
              description={`Master Internet of things & Mobile Services (IOSM)`}
            />
            <ExperienceItem
              date="2013 - 2016"
              school="University of Hassan II - CASABLANCA"
              description={`Fundamental Licence -DataBase field-`}
            />
            <ExperienceItem
              date="2013"
              school="Mohammed VI - CASABLANCA"
              description={`Bacclaureate: Mathematical Science - A -`}
            />
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <h4 className="font-size-lg d-inline-flex flex-column align-items-center text-primary mb-4 mt-3">
            <i className="fas fa-laptop-code font-size-xl text-gray-600 mb-2" />
            <span className="">Skills</span>
          </h4>
          <div className="">
            <ProgressItem
              title="HTML / CSS/ SASS / Javascript(ECS6+) / Typescript"
              value={90}
            />
            <ProgressItem
              title="Reactjs / Redux / React Native / Gatsby"
              value={89}
            />
            <ProgressItem title="Bootstrap / Tailwind " value={88} />
            <ProgressItem
              title="Git / bitbucket / Netlify / Heroku"
              value={60}
            />
            <ProgressItem title="Nodejs / Mongodb / Mongoose" value={40} />
            <ProgressItem title="Google Analytics /  SEO" value={40} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ExperienceItem = ({ date, school, description, job }) => (
  <div className="d-flex flex-column mb-4">
    <span
      className="d-inline-block position-absolute bg-primary rounded-circle"
      style={{ width: 10, height: 10, marginTop: 6, marginLeft: -30 }}
    />
    <span className="font-size-sm text-muted">{date}</span>
    <h5 className="my-1 text-dark">{school}</h5>
    {job && (
      <span className="px-2 py-1 small bg-primary text-white rounded align-self-start mb-2">
        {job}
      </span>
    )}
    <p
      className="m-0 pr-5 font-size-sm text-muted"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
);

const ProgressItem = ({ title, value }) => (
  <div className="mb-3">
    <span className="small uppercase mb-2 d-inline-block">{title}</span>
    <div
      className="bg-white rounded border border-gray-300"
      style={{ padding: 3 }}
    >
      <div
        className="bg-primary rounded"
        style={{
          height: 3,
          width: `${value}%`,
        }}
      />
    </div>
  </div>
);
