import React from 'react';
import ContentHead from './ContentHead';

export default function Interests() {
  return (
    <div className="p-4">
      <ContentHead title="Participations" />
      <div className="">
        <p className="m-0 font-size-md text-gray-600 mr-2 mb-3">
          2018 - 2020 : BMCE, RAM, ONCF, ONEE Hackathons as an expert Screendy
          and organizer.
        </p>
        <p className="m-0 font-size-md text-gray-600 mr-2 mb-3">
          2018 - 2020 : HPS, PSA Bootcamps as an expert Screendy and organizer.
        </p>
        <p className="m-0 font-size-md text-gray-600 mr-2 mb-3">
          2018 - 2020 : Demo days, Scalerators Sessions .. .
        </p>
      </div>
      <ContentHead title="Interests" />
      <div className="d-flex">
        <p className="m-0 font-size-md text-gray-600 mr-2">Embrodery, </p>
        <p className="m-0 font-size-md text-gray-600 mr-2">Traveling, </p>
        <p className="m-0 font-size-md text-gray-600 mr-2">Ping Pong,</p>
        <p className="m-0 font-size-md text-gray-600 mr-2">
          Fictional & competitive games .
        </p>
      </div>
    </div>
  );
}
