import React from 'react';
import MyAvatar from './MyAvatar';
import ReactGA from 'react-ga';

export default function Sidebar () {
   return  <div className="bg-white shadow-lg rounded-lg">
    <MyAvatar />
    <div className="text-center mt-4 mb-4">
      <h1 className="h4 mb-3">
        <span className="font-weight-bold">Laila</span> ELMANSOUR
      </h1>
      <div className="font-weight-light bg-primary font-size-sm text-white d-inline-block px-2 py-1 rounded">
        Frontend Engineer
      </div>

      {/* <div className="mt-4">
        <a
          className="text-gray-500 text-decoration-none mr-3"
          href="https://github.com/layalii"
        >
          <i className="fab fa-github-alt font-size-xl" />
        </a>
        <a
          className="text-gray-500 mr-3 text-decoration-none"
          href="https://www.linkedin.com/in/laila-el-mansour-900843120/"
        >
          <i className="fab fa-linkedin-in font-size-xl" />
        </a>
      </div> */}
    </div>
    <div className="pl-4 pt-4 pb-2 bg-light">
      <ul className="list-unstyled">
        {/* <li className="mb-3">
          <i className="fas fa-birthday-cake mr-2 text-gray-500 font-size-md" />
          <span className="text-gray-600">Soon..</span>
        </li> */}
        <li className="mb-3">
          <i className="fas fa-map-marker-alt mr-2 text-gray-500 font-size-md" />
          <span className="text-gray-600">Ain Sbaa, Casablanca</span>
        </li>
        <li className="mb-3">
          <i className="fas fa-paper-plane mr-2 text-gray-500 font-size-md" />
          <span className="text-gray-600">lailaelmanssour@gmail.com</span>
        </li>
        <li className="mb-3">
          <i className="fab fa-linkedin-in mr-2 text-gray-500 font-size-md" />
          <span>
            <a
              href="https://www.linkedin.com/in/laila-el-mansour/"
              className="text-decoration-none text-gray-600"
            >
              Laila ELMANSOUR
            </a>
          </span>
        </li>
        <li className="mb-3">
          <i className="fab fa-github-alt mr-2 text-gray-500 font-size-md" />
          <span>
            <a
              className="text-gray-600 text-decoration-none mr-3"
              href="https://github.com/layalii"
            >
              Layalii
            </a>
          </span>
        </li>
        <li className="mb-3">
          <i className="fa fa-phone mr-2 text-gray-500 font-size-md" />
          <span className="text-gray-600 text-decoration-none mr-3">
            +2126-16329664
          </span>
        </li>
      </ul>
    </div>
    <div
      className="text-center pb-4 bg-light rounded"
      onClick={(e) => {
        ReactGA.initialize('UA-151947049-1');
        ReactGA.pageview('Download');
      }}
    >
      <a
        href="https://drive.google.com/file/d/1kbmIjHViZxBSvbL3h8djip58HSPU0mcx/view?usp=drive_link"
        download
        target="_blanc"
      >
        <button className="btn btn-primary">
          <i className="fas fa-download mr-2" /> Download CV
        </button>
      </a>
    </div>
  </div>

    };
