import React from 'react';
import ContentHead from './ContentHead';
import Background1 from './images/blog1.png';
import Background2 from './images/blog2.jpg';

export default function Blogs() {
  return (
    <div className="pt-4 px-4">
      <ContentHead title="My Blogs" />
      <div className="row">
        <div className="col-12">
          <WhatidoCard
            src={Background1}
            date="02 February 2024"
            title="React and Concurrent Mode "
            description="Understand in detail what Concurrent Mode is in React"
            link="/blog/concurent-mode"
          />
        </div>
        <div className="col-12">
          <WhatidoCard
            src={Background2}
            date="12 December 2023"
            title="Server Components"
            description="Learn more about Server Components in React"
            link="/blog/server-components"
          />
        </div>
      </div>
    </div>
  );
}

const WhatidoCard = ({ title, description, date, link, src }) => (
  <>
    <div className="rounded shadow-sm bg-white mb-4 row d-flex md:justify-between">
      <div className="col-12 col-lg-8 pl-4 pt-4">
        <h6 className="text-gray-700 font-size-lg">{title}</h6>
        <p className="m-0 text-gray-500 font-size-sm mb-1">{date}</p>
        <p className="text-gray-700">{description}</p>
        <a href={link}>
          <p className="m-0 text-gray-500 mb-2">Continue reading ...</p>
        </a>
      </div>
      <div className="col-12 col-lg-4 p-0">
        <img className="float-right w-100 h-100" src={src} alt="blogimage" />
      </div>
    </div>
  </>
);
