import React from 'react';
import ContentHead from './ContentHead';

export default function About() {
  return (
    <div className="p-4">
      <ContentHead title="About Me" />
      <p className="font-size-md text-gray-600 mb-3">
        I am a frontend engineer from Casablanca, Morocco.
      </p>
      <p className="font-size-md text-gray-600 mb-3">
        My main focus areas are on the front-end, JavaScript, React and Nextjs
        development.
        {/* I also have experience creating APIs and backend services
      mostly in the MEN (MongoDB, ExpressJS, Node.js) stack. */}
      </p>
      <p className="font-size-md text-gray-600 mb-3">
        My first experience with web development was in university where I liked
        how I can create something from nothing, so I spent most of my time
        learning web development and creating web apps using PHP.{' '}
      </p>
      <p className="font-size-md text-gray-600 mb-3">
        Although I learned quite a bit in school, I'm a self-thought for most of
        the technologies I work with in a day to day basis. I enjoy what I do,
        and I would love to continue working on it.
      </p>
      <ContentHead title="Hire Me" />
      <span
        role="img"
        aria-label="hireme"
        className="font-size-md text-gray-600 mb-3"
      >
        👋 Hey, I'm passionate about creating engaging and user-friendly web
        experiences. With more than 5 years of experience in front-end
        development, I've honed my skills in HTML, CSS, and JavaScript, with
        expertise in modern frameworks like React and Nextjs.
      </span>
      <p className="font-size-lg font-weight-normal text-gray-600 mt-3">
        How I can help you?
      </p>
      <ul className="font-size-md text-gray-600">
        <li>
          <span className="text-gray-600">
            Expertise in Front-end Development
          </span>
          : I have a strong foundation in building responsive and accessible web
          applications using the latest web technologies.
        </li>
        <li>
          <span className="text-gray-600">Creative Problem Solver</span>: I
          enjoy tackling complex challenges and finding innovative solutions to
          meet project requirements and exceed expectations.
        </li>
        <li>
          <span className="text-gray-600">Collaborative Team Player</span>: I
          thrive in collaborative environments and value open communication and
          teamwork to deliver high-quality results.
        </li>
      </ul>
      <p className="font-size-md text-gray-600">
        Get{' '}
        <a
          href="mailto:lailaelmanssour@gmail.com"
          rel="noreferrer noopener"
          target="_blank"
        >
          in touch
        </a>{' '}
        to discuss your needs now.
      </p>
    </div>
  );
}
