import React from 'react';

export default function ContentHead({ title }) {
  return (
    <h3 className="h4 mb-4 d-flex flex-column text-gray-800">
      <span className="">{title}</span>
      <span
        className="d-inline-block bg-primary rounded mt-2"
        style={{ height: 4, width: 50 }}
      />
    </h3>
  );
}
